<template>
  <div>
    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
      absolute
      bottom
      color="secondary"
      center
      text
    >
      Copied to clipboard!
    </v-snackbar>
    <v-row align="end" justify="end">
      <v-col cols="12" sm="12" md="6" lg="4">
        <el-input
          ref="searchBar"
          suffix-icon="el-icon-search"
          v-model="search"
          clearable
          placeholder="Search"
        >
          <template slot="prepend">
            <v-btn text @click="createVariable()"><v-icon>add</v-icon></v-btn>
          </template>
        </el-input>
      </v-col>
    </v-row>
    <v-row class="mt-3">
      <v-col cols="12">
        <v-data-table
          dense
          fixed-header
          hide-default-footer
          :loading="loading"
          :items="systemVariables"
          :headers="headers"
          :search="search"
          height="65vh"
          style="cursor: pointer"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-btn class="mr-1" @click="editItem(item)" small icon
              ><v-icon small>edit</v-icon></v-btn
            >
            <v-btn @click="deleteItem(item)" small icon color="danger"
              ><v-icon small>delete</v-icon></v-btn
            >
          </template>
          <template v-slot:[`item.availability`]="{ item }">
            <v-chip outlined small v-if="!item.sharedModel" color="primary">Globally Available</v-chip>
            <v-chip outlined small v-else-if="item.sharedModel" color="success">Shared to {{item.sharedModel.friendlyName}}</v-chip>


          </template>
          
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
      v-model="modal"
      persistent
      width="1500px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Manage Variable</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-btn text color="primary" @click="save" :disabled="!item.name"
            >Save</v-btn
          > -->
          <v-btn
              text
              color="primary"
              :loading="saving"
              @click="save"
              :disabled="!item.name"
              >Save</v-btn
            >
          <v-btn text @click="(modal = false), (item = {})">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="9">
              <v-row align="center">
            <v-col cols="12" sm="12" md="8">
              <v-text-field
            v-model="item.name"
            outlined
            dense
            prepend-inner-icon="search"
            label="Variable Name"
          ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
            <v-autocomplete outlined dense :items="sharedModels" item-text="friendlyName" item-value="id" v-model="item.sharedModelId" label="Attached Model"></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="12" md="8">
              <v-textarea
          rows="3"
          auto-grow
            v-model="item.description"
            outlined
            dense
            label="Description"
          ></v-textarea>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-textarea v-model="item.parameters" label="Parameters" rows="3" outlined dense auto-grow></v-textarea>
              <!-- <v-checkbox class="my-0 py-0" dense v-model="item.shipment" label="Limit to Shipment"></v-checkbox>
              <v-checkbox class="my-0 py-0" dense v-model="item.container" label="Limit to Container"></v-checkbox> -->
            </v-col>
          </v-row>
         
         
          <VueCodeEditor
            v-model="item.code"
            @init="editorInit"
            lang="javascript"
            width="100%"
            height="200px"
            theme="monokai"
            :options="{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              fontSize: 14,
              highlightActiveLine: true,
              enableSnippets: true,
              showLineNumbers: true,
              tabSize: 2,
              showPrintMargin: false,
              showGutter: true,
            }"
            :commands="[
              {
                name: 'save',
                bindKey: { win: 'Ctrl-s', mac: 'Command-s' },
                exec: null,
                readOnly: true,
              },
            ]"
          >
          </VueCodeEditor>
            </v-col>
            <v-col cols="12" md="3">
              <v-subheader>Model Fields</v-subheader>
              <v-list-item v-for="field in filterFields" :key="field.id"  @click="copyModelField(field)">
                <v-list-item-content>
                  <v-list-item-title>
                    {{field.friendlyName}}
                  </v-list-item-title>
                </v-list-item-content>

              </v-list-item>
              <v-subheader>Associated Models</v-subheader>
              <v-treeview
                    style="max-height: 30vh; overflow-y: auto"
                    :items="associations"
                    open-on-click
                    transition
                  >
                    <template v-slot:append="{ item }">
                      <v-chip
                        small
                        v-if="
                          item.type == 'belongsTo' || item.type == 'hasMany'
                        "
                      >
                        <span v-if="item.type == 'belongsTo'">Belongs To</span>
                        <span v-else-if="item.type == 'hasMany'">Has Many</span>
                      </v-chip>
                      <v-btn
                        v-else-if="item.type == 'field'"
                        icon
                        @click="copyModelField(item)"
                      >
                        <v-icon>content_copy</v-icon>
                      </v-btn>
                    </template>
                  </v-treeview>
            </v-col>

          </v-row>

        
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VueCodeEditor from "vue2-code-editor";
export default {
  components: {
    VueCodeEditor,
  },
  data: () => ({
    availableFields: [],
    headers: [
      {
        text: "Action",
        align: "center",
        sortable: false,
        width: '200px',
        value: "action",
      },
      {
        text: "Variable",
        value: "name",
        align: "left",
      },
      {
        text: "Availability",
        value: "availability",
        align: "center",
      },
      {
        text: "Description",
        value: "description",
        align: "left",
      },
      // {
      //   text: "Friendly Name",
      //   value: "friendlyName",
      //   align: "center",
      // },
    ],
    fieldModal: false,
    fieldItem: {},
    item: {},
    loading: false,
    modal: false,
    models: [],
    saving: false,
    search: null,
    searchField: null,
    snackbar: false,
    sharedModels: [],
    systemVariables: [],
    selectedFields: [],
  }),
  watch: {},
  computed: {
    associations(){
      let result = []
      if(this.item.sharedModelId){
        result = this.sharedModels.find(x=>x.id==this.item.sharedModelId).associations
      }
      return result
    },
    filterFields(){
      let result = []
      if(this.item.sharedModelId){
        result = this.sharedModels.find(x=>x.id==this.item.sharedModelId).sharedModelFields
      }
      return result
    }
  },
  async created() {
    this.load();
  },
  methods: {
    copyModelField(item) {
      let text = "${" + item.value + "}";
      navigator.clipboard.writeText(text);
      this.snackbar = true;
    },
    createVariable() {
      this.item = {
        code: "",
      };
      this.modal = true;
    },
    deleteItem(item) {
      this.$confirm(
        "Are you sure you want to delete this variable?",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          await this.$API.updateSystemVariable({
            id: item.id,
            isActive: false,
            isDeleted: true,
          });
          this.getSystemVariables();
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    editItem(item) {
      this.item = item;
      this.modal = true;
    },
    editorInit() {
      // vue2-code-editor/node_modules/
      require("brace/ext/language_tools"); //language extension prerequsite...
      require("brace/mode/html"); //language
      require("brace/mode/javascript");
      require("brace/mode/less");
      require("brace/theme/monokai");
      require("brace/snippets/javascript"); //snippet
    },
    load() {
      this.getSystemVariables();
      this.getModels()
    },
    async getModels() {
      this.sharedModels = await this.$API.getSharedModels();
      this.sharedModels.forEach((x) => {
        x.associations = x.associations.map((x) => ({
          type: x.type,
          name: x.model,
          children: x.fields.map((y) => ({ ...y, type: "field" })),
        }));
      });
    },
    async getSystemVariables() {
      this.loading = true;
      this.systemVariables = await this.$API.getSystemVariables();
      this.loading = false;
    },
    async save() {
      this.saving = true;
      if (this.item.id) {
        await this.$API.updateSystemVariable(this.item);
      } else {
        let result = await this.$API.createSystemVariable(this.item);
        this.systemVariables.push(result);
      }
      this.modal = false;
      this.item = {};
      this.saving = false;
      // this.getSystemVariables();
    },
  },
};
</script>
